/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./Landing/Footer"
import "./layout.css"
import "./home.css"
import StatCounter from 'statcounter'
import { Helmet } from 'react-helmet'


// import LiveChat from 'react-livechat'


const Layout = ({ children }) => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //         description
  //       }
  //     }
  //   }
  // `)


  return (
    <>
      <Helmet>
      <meta name="google-site-verification" content="RpJiKKMa1ptDM0ZCnaP1kOg1sbPxIvV8y7hh9S7bCAw" />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Coinsclone",
          "url": "https://www.coinsclone.com/",
          "logo": "https://www.coinsclone.com/assets/img/logo.png",
          "sameAs": [
            "https://www.facebook.com/coinsclone4exchange",
            "https://twitter.com/coinsclone",
            "https://www.linkedin.com/company/coinsclone/",
            "https://www.youtube.com/channel/UCqXKLrnGCjIxNEvbvpUmrMw/",
            "https://www.instagram.com/coins_clone/",
            "https://www.pinterest.com.au/coinsclone/" 
          ]
        })}
      </script>
      </Helmet>
      <Header />
      
      <main>
        {children}
      </main>
      {/* <ExitIntent /> */}
      <Footer />
      {/* <LiveChat license={12895176} /> */}
      <StatCounter sc_project={11874264} sc_security="45f4b4a8" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
